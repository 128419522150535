module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SPDQ - Speedy Way to Queue.","short_name":"SPDQ","background_color":"#ffffff","description":"SPDQ is a contact-free mobile payment service that has proven to eliminate queues in large venues.","theme_color":"#FFBA00","icon":"src/assets/favicon.png","start_url":"/","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89336eb3a204a5577e91ffcdf908c758"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
