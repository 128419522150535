import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
const config = require('../config')

i18n.use(initReactI18next).init({
  lng: `${config.site.language}`,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        'home.video.title': 'What is SPDQ?',
        'index.video.description':
          'SPDQ is a contact-free mobile payment service that has proven to eliminate queues in large venues. It enables bar staff to serve in 15 seconds with no training - and fans can use the service without any phone connectivity.',
        'hero.minutesSaved': 'Minutes saved queuing',
        'features.learnMore': 'Learn more by reading',
        'features.learnMoreLabel': 'FAQs',
        'diagram.label1': 'SPDQ Click & Collect',
        'diagram.label2': 'Free Bar!',
        'diagram.label3': 'Other mobile Payment providers',
        'diagram.label4': 'Contactless',
        'diagram.label5': 'Cash',
        'diagram.seconds': 'seconds',
        'goCashless.faster': 'Faster than cashless',
        'goCashless.ordering': 'Ordering',
        'goCashless.processing': 'Processing',
        'goCashless.payment': 'Payment',
        'goCashless.service': 'Service',
        'tillCompare.regularTill': 'Regular till',
        'tillCompare.fastLane': 'SPDQ Fast Lane',
        'tillCompare.pints': 'Pints',
        'tillCompare.server': 'server',
        'contact.bookADemo': 'Book a demo',
        'contact.downloadLatestDeck': 'Download our latest deck',
        'contact.name': 'name',
        'contact.yourFullName': 'Your Full Name',
        'contact.email': 'email',
        'contact.phone': 'phone',
        'contact.message': 'message',
        'contact.yourEmail': 'Your Email',
        'contact.yourPhone': 'Your Phone',
        'contact.yourMessage': 'Your Message',
        'contact.contact': 'Contact',
        'navigation.faqs': 'FAQs',
        'navigation.contact': 'Contact',
        'navigation.about': 'About',
        'navigation.caseStudy': 'Case Study',
        'navigation.clients': 'Clients',
        'navigation.newsAndBlog': 'News/Blog',
        'footer.home': 'Home',
        'footer.caseStudy': 'Case Study',
        'footer.contact': 'Contact',
        'footer.about': 'About',
        'footer.termsAndConditions': 'Terms and Conditions',
        'footer.news': 'News',
        'footer.download': 'Download',
        'footer.copyright': 'SPDQ Limited. All rights reserved',
        'footer.builtBy': 'Built by',
        'clients.pageTitle': 'Clients',
        'clients.testimonials': 'Testimonials',
        '404.seo.title': '404: Not found',
        '404.title': 'Not found',
        '404.subtitle': 'You just hit a route that doesn&#39;t exist... the sadness.',
      }
    },
    sa: {
      translation: {
        'home.video.title': 'Wat is SPDQ?',
        'index.video.description':
          "SPDQ is 'n kontakvrye mobiele betaaldiens wat bewys het dat dit toue in groot lokale uitskakel. Dit stel kroegpersoneel in staat om binne 15 sekondes sonder opleiding te bedien - en aanhangers kan die diens sonder enige telefoonverbinding gebruik.",
        'hero.minutesSaved': 'Minute gestoor toustaan',
        'features.learnMore': 'Kom meer te wete deur te lees',
        'features.learnMoreLabel': 'Gereelde vrae',
        'diagram.label1': 'SPDQ Klik en Versamel',
        'diagram.label2': 'Gratis Kroeg!',
        'diagram.label3': 'Ander mobiele betalingsverskaffers',
        'diagram.label4': 'Kontakloos',
        'diagram.label5': 'Kontant',
        'diagram.seconds': 'sekondes',
        'goCashless.faster': 'Vinniger as kontantloos',
        'goCashless.ordering': 'Bestelling',
        'goCashless.processing': 'Verwerking',
        'goCashless.payment': 'Betaling',
        'goCashless.service': 'Diens',
        'tillCompare.regularTill': 'Gereelde tot',
        'tillCompare.fastLane': 'SPDQ vinnige baan',
        'tillCompare.pints': 'Pinte',
        'tillCompare.server': 'bediener',
        'contact.bookADemo': "Bespreek 'n demo",
        'contact.downloadLatestDeck': 'Laai ons nuutste dek af',
        'contact.name': 'naam',
        'contact.yourFullName': 'Jou volle naam',
        'contact.email': 'e-pos',
        'contact.phone': 'foon',
        'contact.message': 'boodskap',
        'contact.yourEmail': 'Jou epos',
        'contact.yourPhone': 'Jou foon',
        'contact.yourMessage': 'Jou boodskap',
        'contact.contact': 'Kontak',
        'navigation.faqs': 'Gereelde vrae',
        'navigation.contact': 'Kontak',
        'navigation.about': 'Oor',
        'navigation.caseStudy': 'Gevallestudie',
        'navigation.clients': 'Kliënte',
        'navigation.newsAndBlog': 'Nuus/Blog',
        'footer.home': 'Home',
        'footer.caseStudy': 'Gevallestudie',
        'footer.contact': 'Kontak',
        'footer.about': 'Oor',
        'footer.termsAndConditions': 'Bepalings en Voorwaardes',
        'footer.news': 'Nuus',
        'footer.download': 'Aflaai',
        'footer.copyright': 'SPDQ Limited. Alle regte voorbehou',
        'footer.builtBy': 'Gebou deur',
        'clients.pageTitle': 'Kliënte',
        'clients.testimonials': 'Getuigskrifte',
        '404.seo.title': '404: Nie gevind nie',
        '404.title': 'Nie gevind nie',
        '404.subtitle': 'Jy het net \'n roete getref wat nie bestaan nie... die hartseer.',
      }
    }
  }
})

export default i18n
