const language = process.env.GATSBY_SITE_LANGUAGE || 'en'

var siteSettings = {
  en: {
    siteTitle: "SPDQ - Speedy Way to Queue.",
    description: "SPDQ is a contact-free mobile payment service that has proven to eliminate queues in large venues.",
    siteUrl: 'https://spdq.app'
  },
  sa: {
    siteTitle: "SPDQ - HALFTYD DRANK. IN DIE HALFTE TYD.",
    description: "SPDQ is 'n kontakvrye mobiele betaaldiens wat bewys het dat dit toue in groot lokale uitskakel.",
    siteUrl: 'https://spdq.app'
  }
};

var siteLanguage = siteSettings[language]

var config = {
  site: {
    siteTitle: siteLanguage.siteTitle,
    language: language,
    siteSettings: siteSettings,
    shortName: "SPDQ",
    siteUrl: siteLanguage.siteUrl,
    bgColor: "#ffffff",
    themeColor: "#FFBA00",
    favIcon: "src/assets/favicon.png",
    description: siteLanguage.description,
  },
  project: {
    projectId: "fxgsdm3u",
    dataset: "production",
  },
};

module.exports = config;
